import React, { useState } from "react";
//MUI
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import "./index.css";
import axios from "axios";
import ruleta from '../assets/ruleta-completo.mp3';
import ambiente from '../assets/ambiente.mp3';

const Wheel = ({ 
    items, 
    colores, 
    withBtnJugar, 
    onSelectItem,
    codigoURL,
    onFinished }) => {

  let posWinningSegment = 0;
  let winningSegment = "";


  const [selectItem, setselectItem] = useState(null);
  const [isStarted, setStarted] = useState(false);
  const [isDobleChance, setDobleChance] = useState(false);

  const audioRuleta = new Audio(ruleta);
  const audioAmbiente = new Audio(ambiente);


  const mostrarganador = () => {
    onFinished(winningSegment)
    setTextoBtn(winningSegment)      
    if(winningSegment === 'Doble chance'){
      setDobleChance(true)
    }
  }

  const spin = () => {    
    async function fetchPosts() {
      try {
      const response = await axios.get(
        "https://"+process.env.REACT_APP_BACKEND_HOST+"/api/ruleta/jugar/"+codigoURL, {timeout: 10000} 
      );  
      return response
    }
    catch (err) {
        console.error(err);
    }
    }
    fetchPosts().then(response=>{

      if(response.status == 200){
        winningSegment = response.data?.resultado
        posWinningSegment = response.data?.posicion
      } else {
        window.location.reload();
      }      

      if (selectItem === null) {
        const selectedItem = posWinningSegment-1;

        if (onSelectItem) {
          onSelectItem(selectedItem);
        }
        setselectItem(selectedItem);
      } else {
        setselectItem(null);
        setTimeout(selectItem, 500);
      }

      if(!isStarted){
      setTextoBtn("...suerte...")
      setStarted(true)      
      audioRuleta.play();
      audioAmbiente.play();
      }
      setTimeout(mostrarganador, 9000);
    })   

  };

  const wheelVars = {
    "--nb-item": items.length,
    "--selected-item": selectItem
  };
  

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    padding: '6px 12px',
    border: '2px solid',
    lineHeight: 2.5,
    backgroundColor: '#609550',
    borderColor: '#d4af37',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#d4af37',
      borderColor: '#0062cc',
      boxShadow: 'none',
      color: 'white',
      fontSize: 30
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });

  const BootstrapButtonDobleChance = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 30,
    padding: '6px 12px',
    border: '2px solid',
    lineHeight: 2.5,
    backgroundColor: '#ff0000',
    borderColor: '#ff0000',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#609550',
      borderColor: '#0062cc',
      boxShadow: 'none',
      color: 'white',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });

  const spinning = selectItem !== null ? "spinning" : "";
  //const [isStarted, setStarted] = useState(false);
  const [textoBtn, setTextoBtn] = useState("JUGAR");
  return (
    <>
      <div className="wheel-container">
        <div className={`wheel ${spinning}`} style={wheelVars}>
          {items.map((item, index) => (
            <div
              className="wheel-item"
              key={index}
              style={{
                "--item-nb": index,
                "color": colores[index],
                "font-weight": "900",
                "text-wrap": "nowrap",
                "font-size":"23px",
              } }
            >
              {item}
            </div>
          ))}
          
        </div>
        
      </div>
      <div style={{
        paddingTop: 20
      }}>
      <BootstrapButton  variant="contained" 
                        disableRipple disabled = {isStarted} 
                        startIcon={<EmojiEventsIcon />} 
                        endIcon={<EmojiEventsIcon />} 
                        onClick={()=>spin()}>
                      {textoBtn}
      </BootstrapButton>

      {isDobleChance ? (
      <div style={{paddingTop: "10px"}}>
        <BootstrapButtonDobleChance variant="contained" 
                                    disableRipple onClick={()=>window.location.reload()}>
                        Presione para volver a intentar
        </BootstrapButtonDobleChance>
      </div>
      ):(<div></div>)}
    </div>

    </>
  );
};

export default Wheel;
