import background from "./background.png";
import logororomiem from "./logo-roromiem.png";
import textologo from "./texto-logo.png";
import logo from "./logo.png";

const IMAGES = {
    background,
    logororomiem,
    textologo,
    logo
}
export default IMAGES